<template>
  <Modal v-model="showForm" title="新增资产" :mask-closable="false">
    <Form
      ref="addForm"
      :model="addForm"
      :rules="addFormRule"
      label-colon
      :label-width="100"
    >
      <div class="divider">基本信息</div>
      <FormItem label="卡片编号" prop="cardNo">
        <Input
          v-model="addForm.cardNo"
          :maxlength="10"
          placeholder="卡片编号"
          clearable
        />
      </FormItem>
      <FormItem label="资产编码" prop="encoding">
        <Input
          v-model="addForm.encoding"
          :maxlength="20"
          placeholder="资产编码"
          clearable
        />
      </FormItem>
      <FormItem label="资产名称" prop="name">
        <Input
          v-model="addForm.name"
          :maxlength="30"
          placeholder="资产名称"
          clearable
        />
      </FormItem>
      <FormItem label="资产类别" prop="classifyName">
        <treeselect
          v-model="addForm.classifyName"
          :options="classifyList"
          placeholder="资产类别"
          noOptionsText="暂无数据"
          noResultsText="没有匹配数据"
          clearable
        />
      </FormItem>
      <FormItem label="资产状态" prop="sdName">
        <Select v-model="addForm.sdName" placeholder="请选择" clearable>
          <Option v-for="item in sdNameList" :value="item.id" :key="item.id"
            >{{ item.name }}
          </Option>
        </Select>
      </FormItem>
      <FormItem label="品牌">
        <Input
          v-model="addForm.brand"
          :maxlength="30"
          placeholder="品牌"
          clearable
        />
      </FormItem>
      <FormItem label="规格型号">
        <Input
          v-model="addForm.model"
          :maxlength="30"
          placeholder="规格型号"
          clearable
        />
      </FormItem>
      <FormItem label="出厂编码">
        <Input
          v-model="addForm.snCode"
          @on-keyup="btKeyUp($event)"
          @input="input"
          :maxlength="30"
          placeholder="出厂编码"
          clearable
        />
      </FormItem>
      <FormItem label="所属公司" prop="companyId">
        <Select
          v-model="addForm.companyId"
          placeholder="请选择"
          filterable
          clearable
        >
          <Option v-for="item in companyList" :value="item.id" :key="item.id"
            >{{ item.name }}
          </Option>
        </Select>
      </FormItem>
      <FormItem label="所在位置" prop="locationId">
        <treeselect
          v-model="addForm.locationId"
          :options="locationArray"
          placeholder="所在位置"
          noOptionsText="暂无数据"
          noResultsText="没有匹配数据"
          clearable
        />
      </FormItem>
      <FormItem label="资产照片">
        <Upload
          ref="upload"
          multiple
          type="drag"
          :max-size="20 * 1024"
          :action="uploadFiles"
          :show-upload-list="false"
          :format="['jpg', 'png', 'JPG', 'JPEG']"
          accept=".jpg,.png,.JPG,.JPEG"
          :before-upload="beforeUpload"
          :on-exceeded-size="handExceeded"
          :on-success="handleSuccess"
          :headers="header"
        >
          <div class="upload-btn-l">
            <Icon type="md-cloud-upload"></Icon>
            <p class="tips">点击上传或拖动文件到这里上传</p>
          </div>
        </Upload>
        <div class="upload-image-list" v-for="(item, index) in imgUrl">
          <template>
            <img :src="item.url" />
            <div class="upload-image-list-cover">
              <Icon type="ios-eye" @click.native="handleView(item.url)"></Icon>
              <Icon type="ios-trash" @click.native="handleRemove(index)"></Icon>
            </div>
          </template>
        </div>
      </FormItem>
      <Modal title="查看图片" v-model="visible" footer-hide>
        <img :src="imageUrl" v-if="visible" style="width: 100%" />
      </Modal>
      <div class="divider">来源信息</div>
      <FormItem label="资产管理员" prop="storeman">
        <Select
          v-model="addForm.storeman"
          @on-change="getUser"
          placeholder="请选择"
          filterable
          clearable
        >
          <Option v-for="item in userArray" :value="item.id" :key="item.id"
            >{{ item.name }}
          </Option>
        </Select>
      </FormItem>
      <FormItem label="资产来源" prop="source">
        <Select v-model="addForm.source" placeholder="请选择" clearable>
          <Option v-for="item in sourceList" :value="item.id" :key="item.id"
            >{{ item.name }}
          </Option>
        </Select>
      </FormItem>
      <FormItem label="使用部门/车间">
        <treeselect
          v-model="addForm.departmentId"
          :max-height="130"
          :options="deptTreeOptions"
          placeholder="请选择部门"
          loadingText="加载中..."
          noOptionsText="暂无数据"
          noResultsText="没有匹配数据"
          @select="handleDeptChange"
        />
      </FormItem>
      <FormItem label="保管人" prop="user">
        <Select
          v-model="addForm.user"
          placeholder="请选择"
          filterable
          clearable
        >
          <Option
            v-for="(item, index) in userNameArray"
            :value="item.id"
            :key="index"
            >{{ item.name }}</Option
          >
        </Select>
      </FormItem>
      <FormItem label="生产厂家" prop="manufacturer">
        <Input
          v-model="addForm.manufacturer"
          :maxlength="10"
          placeholder="生产厂家"
          clearable
        />
      </FormItem>
      <FormItem label="生产日期" prop="productionDate">
        <Input
          v-model="addForm.productionDate"
          :maxlength="10"
          placeholder="生产日期"
          clearable
        />
      </FormItem>
      <FormItem label="使用月限">
        <Input
          v-model="addForm.maximumMonthesToUse"
          @on-keyup="maximumMonthesToUse($event)"
          number
          :maxlength="3"
          placeholder="使用月限"
          clearable
        />
      </FormItem>
      <FormItem label="原值" prop="amount">
        <Input
          v-model="addForm.amount"
          @on-keyup="amount($event)"
          :maxlength="11"
          placeholder="金额（元）"
          clearable
        />
      </FormItem>
      <FormItem label="计量单位">
        <Input
          v-model="addForm.unit"
          :maxlength="2"
          placeholder="计量单位"
          clearable
        />
      </FormItem>
      <FormItem label="附件">
        <Upload
          ref="attachment"
          multiple
          type="drag"
          :max-size="20 * 1024"
          :action="uploadFiles"
          :default-file-list="files"
          :format="[
            'jpg',
            'png',
            'JPG',
            'JPEG',
            'xlsx',
            'xls',
            'doc',
            'docx',
            'pdf',
          ]"
          accept=".jpg,.png,.JPG,.JPEG,.xlsx,.xls,.doc,.docx,.pdf"
          :before-upload="handleBeforeUpload"
          :on-exceeded-size="handExceeded"
          :on-remove="removeFile"
          :on-success="handleFileSuccess"
          :headers="header"
        >
          <div class="upload-btn-l">
            <Icon type="md-cloud-upload"></Icon>
            <p class="tips">点击上传或拖动文件到这里上传</p>
          </div>
        </Upload>
      </FormItem>
      <div class="divider">其它信息</div>
      <FormItem label="开始使用日期">
        <DatePicker
          v-model="addForm.startTime"
          placeholder="开始使用日期"
          clearable
        />
      </FormItem>
      <FormItem label="建卡日期">
        <DatePicker
          v-model="addForm.cardCreatedDate"
          placeholder="建卡日期"
          clearable
        />
      </FormItem>
      <FormItem label="入库时间" prop="warehouseTime">
        <DatePicker
          v-model="addForm.warehouseTime"
          placeholder="入库时间"
          clearable
        />
      </FormItem>
      <FormItem label="备注">
        <Input
          v-model="addForm.memo"
          type="textarea"
          :maxlength="200"
          placeholder="备注"
          clearable
        />
      </FormItem>
    </Form>
    <div slot="footer">
      <Button type="default" @click="closeModal(false)">取消</Button>
      <Button type="primary" :loading="loading" @click="handleSubmit"
        >确定</Button
      >
    </div>
  </Modal>
</template>
<script>
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import {reqGetDictionaryListByCode, uploadFileURL} from '../../../api/system-api'
  import {
    reqAdd,
    reqGetClassify,
    reqGetCompany,
    reqGetLocation,
    getUserListByRoleName,
    reqGetDeptTreeOptions,
    getUserByDeptId,
  } from '../../../api/asset-api'

  export default {
    components: { Treeselect },
    name: 'addManifest',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        deptTreeOptions: [],
        uploadImageName: [],
        uploadFileName: [],
        image: [],
        files: [],
        imgUrl: [],
        copyFiles: [],
        copyImage: [],
        imageUrl: '',
        visible: false,
        uploadFiles: uploadFileURL,
        showForm: this.value,
        loading: false,
        userArray: [],
        userNameArray: [],
        companyList: [],
        copyCompanyList: [],
        sourceList: [],
        sdNameList: [],
        classifyList: [],
        locationArray: [],
        header: {},
        addForm: {
          encoding: '',
          name: '',
          startTime: null,
          classifyName: null,
          brand: '',
          cardNo: '',
          model: '',
          snCode: '',
          status: 13,
          createTime: '',
          warehouseTime: new Date(),
          source: '',
          deptTreeOptions: '',
          sdName: '',
          amount: '',
          unit: '',
          usageDueDate: null,
          maintenanceDueDate: null,
          memo: '',
          isInventory: '',
          companyId: '',
          locationId: null,
          storeman: '',
          departmentId: null,
          userName: '',
          user: '',
          cardCreatedDate: null,
          maximumMonthesToUse: '',
          productionDate: '',
          manufacturer: '',
        },
        addFormRule: {
          encoding: [{ message: '请输入资产编码', trigger: 'blur' }],
          name: [
            { required: true, message: '请输入资产名称', trigger: 'blur' },
          ],
          source: [
            {
              required: true,
              type: 'integer',
              message: '请选择资产来源',
              trigger: 'change',
            },
          ],
          deptTreeOptions: [
            { message: '请选择使用部门/车间', trigger: 'change' },
          ],
          classifyName: [
            { required: true, message: '请输入资产类别', trigger: 'change' },
          ],
          warehouseTime: [
            {
              required: true,
              type: 'date',
              message: '请选择入库时间',
              trigger: 'change',
            },
          ],
          locationId: [
            { required: true, message: '请选择所在地点', trigger: 'change' },
          ],
          companyId: [
            { required: true, message: '请选择所属公司', trigger: 'change' },
          ],
          storeman: [
            { required: true, message: '请选择资产管理员', trigger: 'change' },
          ],
          sdName: [
            {
              required: true,
              type: 'integer',
              message: '请输入资产状态',
              trigger: 'change',
            },
          ],
          user: [{ message: '请选择使用人', trigger: 'change' }],
          departmentId: [{ message: '请选择使用部门/车间', trigger: 'change' }],
          amount: [
            {
              trigger: 'blur',
              validator: (rule, value, callback) => {
                if (value) {
                  if ((value + '').length > 11) {
                    return callback(new Error('最大金额99999999.99'))
                  } else if (value * 1 > 100000000) {
                    return callback(new Error('最大金额99999999.99'))
                  } else if (value * 1 < 0) {
                    return callback(new Error('最小金额不得小于0'))
                  }
                }
                return callback()
              },
            },
          ],
          productionDate: [
            {
              trigger: 'blur',
              validator: (rule, value, callback) => {
                if (value) {
                  let regY = /^[1-9][0-9]{3}$/
                  let regYM = /^([1-9][0-9]{3})-(0[1-9]|1[0-2])$/
                  let regYMD =
                    /^(([1-9][0-9]{3})-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([1-9][0-9])(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29)$/
                  if (
                    !regY.test(value) &&
                    !regYM.test(value) &&
                    !regYMD.test(value)
                  ) {
                    return callback(
                      new Error(
                        '生产日期格式不正确！例如：yyyy或yyyy-mm或yyyy-mm-dd'
                      )
                    )
                  }
                }
                return callback()
              },
            },
          ],
        },
      }
    },
    methods: {
      getDeptTreeOptions() {
        this.deptTreeOptions = []
        reqGetDeptTreeOptions()
          .then((res) => {
            this.deptTreeOptions = res.data.data
          })
          .catch(() => {
            this.deptTreeOptions = []
          })
      },
      getUser() {
        getUserListByRoleName({ roleName: '资产管理员' })
          .then((res) => {
            this.userArray = res.data
          })
          .catch(() => {
            this.userArray = null
          })
      },
      handleDeptChange(node, instanceId) {
        getUserByDeptId({ dDeptId: node.id })
          .then((res) => {
            this.userNameArray = res.data
          })
          .catch(() => {
            this.userNameArray = null
          })
      },
      handleView(url) {
        this.imageUrl = url
        this.visible = true
      },
      handleRemove(index) {
        this.imgUrl.splice(index, 1)
        this.image.splice(index, 1)
        this.uploadImageName.splice(index, 1)
      },
      handleSubmit() {
        this.getCompany()
        this.$refs['addForm'].validate((valid) => {
          if (valid) {
            this.loading = true
            let image = []
            if (this.image.length > 0) {
              this.image.forEach((item) => {
                image.push(item.name)
              })
            }
            let files = []
            if (this.files.length > 0) {
              this.files.forEach((item) => {
                files.push(item.name)
              })
            }
            let paras = {
              uploadImageName: this.uploadImageName,
              uploadFileName: this.uploadFileName,
              files: files,
              image: image,
              encoding: this.addForm.encoding,
              name: this.addForm.name,
              classifyName: this.addForm.classifyName,
              brand: this.addForm.brand,
              cardNo: this.addForm.cardNo,
              model: this.addForm.model,
              snCode: this.addForm.snCode,
              status: this.addForm.status,
              createTime: this.addForm.createTime,
              company: this.addForm.companyId,
              source: this.addForm.source,
              deptTreeOptions: this.addForm.deptTreeOptions,
              departmentId: this.addForm.departmentId,
              sdName: this.addForm.sdName,
              amount: this.addForm.amount,
              unit: this.addForm.unit,
              usageDueDate: this.addForm.usageDueDate,
              maintenanceDueDate: this.addForm.maintenanceDueDate,
              warehouseTime: this.addForm.warehouseTime,
              startTime: this.addForm.startTime,
              memo: this.addForm.memo,
              locationId: this.addForm.locationId,
              storeman: this.addForm.storeman,
              cardCreatedDate: this.addForm.cardCreatedDate,
              maximumMonthesToUse: this.addForm.maximumMonthesToUse,
              productionDate: this.addForm.productionDate,
              manufacturer: this.addForm.manufacturer,
              user: this.addForm.user,
            }
            reqAdd(paras)
              .then((res) => {
                this.loading = false
                if (res.data.code === 1) {
                  this.closeModal(false)
                  this.$emit('getAsset')
                  this.$Message.success('添加成功')
                  this.$refs.upload.clearFiles()
                  this.$refs.attachment.clearFiles()
                  this.file = []
                } else if (res.data.code === 2) {
                  this.$Message.error('出厂编码已经存在')
                  this.$refs.upload.clearFiles()
                  this.$refs.attachment.clearFiles()
                  this.file = []
                } else if (res.data.code === 3) {
                  this.$Message.error('资产编码已存在')
                  this.$refs.upload.clearFiles()
                  this.$refs.attachment.clearFiles()
                  this.file = []
                } else if (res.data.code === 4) {
                  this.$Message.error('卡片编号已存在')
                  this.$refs.upload.clearFiles()
                  this.$refs.attachment.clearFiles()
                  this.file = []
                } else {
                  this.$Message.error('添加失败')
                  this.$refs.upload.clearFiles()
                  this.$refs.attachment.clearFiles()
                  this.file = []
                }
              })
              .catch(() => {
                this.loading = false
                this.closeModal(false)
                this.$Message.error('添加失败')
                this.$refs.upload.clearFiles()
                this.$refs.attachment.clearFiles()
                this.file = []
              })
          }
        })
      },
      // 资产来源
      getSource() {
        reqGetDictionaryListByCode({ code: 'source_of_asset' })
          .then((res) => {
            this.sourceList = res.data.data
          })
          .catch(() => {
            this.sourceList = []
          })
      },
      // 资产状态
      getSdName() {
        reqGetDictionaryListByCode({ code: 'status_of_asset' })
          .then((res) => {
            this.sdNameList = res.data.data
          })
          .catch(() => {
            this.sdNameList = []
          })
      },
      getClassifyName() {
        reqGetClassify()
          .then((res) => {
            let options = []
            let child = []
            if (res.data.data !== null) {
              for (var i = 0; i < res.data.data.length; i++) {
                options.push({
                  id: res.data.data[i].id,
                  label: res.data.data[i].name,
                })
                if (!res.data.data[i].children) {
                  child.push({ length: 0 })
                } else {
                  child.push(res.data.data[i].children)
                }
              }
              let result = []
              options.forEach((item, index) => {
                let obj = {}
                obj.id = item.id
                obj.label = item.label
                if (child[index].length !== 0) {
                  obj.children = this.getTree(child[index])
                }
                result.push(obj)
              })
              this.classifyList = result
            }
          })
          .catch(() => {
            this.classifyList = []
          })
      },
      getTree(child = []) {
        let arr = []
        if (child.length !== 0) {
          child.forEach((element) => {
            let obj = {}
            if (element.hasOwnProperty('name')) {
              obj.label = element.name
              obj.id = element.id
              if (element.hasOwnProperty('children')) {
                obj.children = this.getTree(element.children)
              }
            }
            arr.push(obj)
          })
        }
        return arr
      },
      // 所属公司
      getCompany() {
        reqGetCompany()
          .then((res) => {
            this.companyList = res.data.data
            this.copyCompanyList = res.data.data
            let companyId = this.copyCompanyList.filter((item) => {
              return JSON.stringify(1).includes(item.isDefault)
            })
            if (companyId.length !== 0) {
              this.addForm.companyId = companyId[0].id
            }
          })
          .catch(() => {
            this.companyList = []
          })
      },
      // 所在位置
      getLocation() {
        reqGetLocation()
          .then((res) => {
            let options = []
            let child = []
            if (res.data.data !== null) {
              for (var i = 0; i < res.data.data.length; i++) {
                options.push({
                  id: res.data.data[i].id,
                  label: res.data.data[i].name,
                })
                if (!res.data.data[i].children) {
                  child.push({ length: 0 })
                } else {
                  child.push(res.data.data[i].children)
                }
              }
              let result = []
              options.forEach((item, index) => {
                let obj = {}
                obj.id = item.id
                obj.label = item.label
                if (child[index].length !== 0) {
                  obj.children = this.getTree(child[index])
                }
                result.push(obj)
              })
              this.locationArray = result
            }
          })
          .catch(() => {
            this.locationArray = []
          })
      },
      closeModal(val) {
        this.$emit('input', val)
      },
      handleBeforeUpload(file) {
        this.copyFiles.push(file)
        const check = this.copyFiles.length <= 5
        if (!check) {
          this.$Notice.warning({
            title: '附件最多上传5个',
          })
          return check
        }
        this.files.push(file)
      },
      removeFile(file, fileList) {
        this.files = [...fileList]
        let idx = this.uploadFileName.findIndex(
          (item) => item === file.currentName || item === file.response?.data
        )
        if (idx >= 0) {
          this.uploadFileName.splice(idx, 1)
        }
      },
      beforeUpload(file) {
        this.copyImage.push(file)
        const check = this.copyImage.length <= 5
        if (!check) {
          this.$Notice.warning({
            title: '图片最多上传5张',
          })
          return check
        }
        this.image.push(file)
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          const _base64 = reader.result
          this.imgUrl.push({ url: _base64, name: file.name })
        }
      },
      //上传文件大小
      handExceeded() {
        this.$Message.error('文件内容不得超过20M')
      },
      //上传成功
      handleSuccess(res, file) {
        if (res.code === 1) {
          this.uploadImageName.push(res.data)
          this.$Message.success('上传成功')
        } else {
          this.$Message.error(res.data)
        }
      },
      //上传成功
      handleFileSuccess(res, file) {
        if (res.code === 1) {
          this.uploadFileName.push(res.data)
          this.$Message.success('上传成功')
        } else {
          this.$Message.error(res.data)
        }
      },
      //控制不能输入特殊字符
      btKeyUp($event) {
        if ('undefined' !== typeof $event) {
          this.addForm.snCode = this.addForm.snCode.replace(
            /[`~!@#$%^&*()+=<>?:"{}|,.\;'[\]·~！@#￥%……&*（）——+={}|《》？：“”【】、；‘’，。、]/g,
            ''
          )
        }
      },
      amount($event) {
        if ('undefined' !== typeof $event) {
          this.addForm.amount = this.addForm.amount.replace(
            /^\D*(\d*(?:\.\d{0,2})?).*$/g,
            '$1'
          )
        }
      },
      input() {
        var reg =
          /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi
        this.addForm.snCode = this.addForm.snCode.replace(reg, '')
      },
      maximumMonthesToUse($event) {
        if ('undefined' !== typeof $event) {
          this.addForm.maximumMonthesToUse =
            this.addForm.maximumMonthesToUse.replace(/^(0+)|[^\d]+/g, '')
        }
      },
    },
    watch: {
      value(val) {
        this.showForm = val
      },
      showForm(val) {
        //当重新显示增加数据的时候重置整个form表单
        if (val) {
          this.addForm.storeman = ''
          this.addForm.userName = ''
          this.addForm.name = ''
          this.addForm.classifyName = null
          this.addForm.brand = ''
          this.addForm.cardNo = ''
          this.addForm.model = ''
          this.addForm.snCode = ''
          this.addForm.status = 13
          this.addForm.createTime = ''
          this.addForm.warehouseTime = null
          this.addForm.source = ''
          this.addForm.deptTreeOptions = ''
          this.addForm.departmentId = null
          this.addForm.sdName = ''
          this.addForm.amount = ''
          this.addForm.unit = ''
          this.addForm.usageDueDate = null
          this.addForm.maintenanceDueDate = null
          this.addForm.startTime = null
          this.addForm.memo = ''
          this.addForm.isInventory = ''
          this.addForm.companyId = ''
          this.addForm.locationId = null
          this.addForm.cardCreatedDate = null
          this.addForm.maximumMonthesToUse = ''
          this.addForm.productionDate = ''
          this.addForm.manufacturer = ''
            this.uploadImageName = []
            this.uploadFileName = []
            this.image = []
            this.files = []
            this.imgUrl = []
            this.copyImage = []
            this.copyFiles = []
            this.getLocation()
          this.getClassifyName()
          this.getSource()
          this.getDeptTreeOptions()
          this.getSdName()
          this.getCompany()
          this.getUser()
          this.$refs['addForm'].resetFields()
        } else {
          // 反之则关闭页面
          this.closeModal(val)
        }
      },
    },
      mounted() {
          let token = sessionStorage.getItem('Authorization')
          this.header = { Authorization: token }
      }
  }
</script>

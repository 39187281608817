<template>
    <Modal title="查看资产" v-model="showForm" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" label-colon :label-width="100">
            <div class="divider">基本信息</div>
            <FormItem label="卡片编号">{{ lookData.cardNo }}</FormItem>
            <FormItem label="资产编码">{{ lookData.encoding }}</FormItem>
            <FormItem label="资产名称">{{ lookData.name }}</FormItem>
            <FormItem label="资产类别">{{ lookData.classifyName }}</FormItem>
            <FormItem label="品牌">{{ lookData.brand }}</FormItem>
            <FormItem label="规格型号">{{ lookData.model }}</FormItem>
            <FormItem label="出厂编码">{{ lookData.snCode }}</FormItem>
            <FormItem label="资产状态">{{ lookData.sdName }}</FormItem>
            <FormItem label="所属公司">{{ lookData.companyName }}</FormItem>
            <FormItem label="存放地点">{{ lookData.locationName }}</FormItem>
            <FormItem label="资产照片">
                <div class="upload-image-list" v-for="item in lookData.fileList" v-if="item.type === 'img'">
                    <template>
                        <img :src="downloadFileURL+item.currentName+'/'">
                        <div class="upload-image-list-cover">
                            <Icon type="ios-eye" @click.native="handleView(item.currentName)"></Icon>
                        </div>
                    </template>
                </div>
            </FormItem>
            <FormItem label="二维码">
                <vue-qr logoSrc="" :text="lookData.id" :size="128"></vue-qr>
            </FormItem>
            <Modal title="查看图片" v-model="visible" footer-hide>
                <img :src="imageUrl" v-if="visible" style="width: 100%">
            </Modal>
            <div class="divider">来源信息</div>
            <FormItem label="增加方式名称">{{ lookData.sourceName }}</FormItem>
            <FormItem label="原值（元）">{{ lookData.amount | currency('') }}</FormItem>
            <!--<FormItem label="累计折旧">{{ lookData.depreciation | currency('') }}</FormItem>-->
            <FormItem label="计量单位">{{ lookData.unit }}</FormItem>
            <FormItem label="资产管理员">{{ lookData.storemanName }}</FormItem>
            <FormItem label="附件">
                <div class="attachment" v-for="item in lookData.fileList" v-if="item.type === 'file'"
                     @click="downloadFile(item.currentName, item.orignalName)">
                    {{ item.orignalName }}
                </div>
            </FormItem>
            <div class="divider">其它信息</div>
            <FormItem label="开始使用日期">{{ lookData.startTime }}</FormItem>
            <FormItem label="建卡日期">{{ lookData.cardCreatedDate }}</FormItem>
            <FormItem label="使用部门/车间">{{ lookData.departmentName }}</FormItem>
            <FormItem label="保管人">{{ lookData.userName }}</FormItem>
            <FormItem label="生产厂家">{{ lookData.manufacturer }}</FormItem>
            <FormItem label="生产日期">{{ lookData.productionDate }}</FormItem>
            <FormItem label="使用月限">{{ lookData.maximumMonthesToUse }}</FormItem>
            <FormItem label="备注">{{ lookData.memo }}</FormItem>
            <FormItem label="创建人">{{ lookData.createName }}</FormItem>
            <FormItem label="创建时间">{{ lookData.createTime }}</FormItem>
            <FormItem label="修改人">{{ lookData.updatedName }}</FormItem>
            <FormItem label="修改时间">{{ lookData.updatedTime }}</FormItem>
        </Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>

<script>

import {downloadFileURL} from '../../../api/system-api'
import {reqGetAssetById} from '../../../api/asset-api'
import vueQr from 'vue-qr'
import {currency} from "../../../common/currency";

export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: ''
    },
    components: {vueQr},
    filters: {
        currency: currency
    },
    data() {
        return {
            lookData: {id: ''},
            usageDueDate: null,
            maintenanceDueDate: null,
            downloadFileURL: downloadFileURL,
            imageUrl: '',
            visible: false,
            showForm: false,
            viewForm: {}
        }
    },
    methods: {
        getAssetById() {
            reqGetAssetById({id: this.id}).then((res) => {
                this.lookData = res.data.data;
                if (res.data.data.usageDueDate !== null) {
                    this.usageDueDate = this.$moment(res.data.data.usageDueDate).format('YYYY-MM-DD')
                }
                if (res.data.data.maintenanceDueDate !== null) {
                    this.maintenanceDueDate = this.$moment(res.data.data.maintenanceDueDate).format('YYYY-MM-DD')
                }
            }).catch(() => {
                this.lookData = [];
            })
        },
        downloadFile(currentName, orignalName){
            let downloadElement = document.createElement("a");
            let href = downloadFileURL+currentName+'/';
            downloadElement.href = href;
            downloadElement.download = orignalName; //下载后文件名
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象
        },
        handleView(url) {
            this.imageUrl = downloadFileURL + url + '/';
            this.visible = true;
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.getAssetById();
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    },
    mounted() {
    }
}
</script>

<template>
    <Modal v-model="showForm" title="编辑资产" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="110">
            <div class="divider">基本信息</div>
            <FormItem label="卡片编号" prop="cardNo">
                <Input v-model="editForm.cardNo" :maxlength="10" placeholder="卡片编号" clearable/>
            </FormItem>
            <FormItem label="资产编码" prop="encoding">
                <Input v-model="editForm.encoding" :maxlength="20" placeholder="资产编码"
                       clearable/>
            </FormItem>
            <FormItem label="资产名称" prop="name">
                <Input v-model="editForm.name" :maxlength="30" placeholder="资产名称" clearable/>
            </FormItem>
            <FormItem label="资产类别" prop="classifyId">
                <treeselect v-model="editForm.classifyId" :options="classifyList" placeholder="全部"
                            noOptionsText="暂无数据" noResultsText="没有匹配数据" clearable/>
            </FormItem>
            <FormItem label="资产状态" prop="status">
                <Select v-model="editForm.status" placeholder="请选择" filterable clearable>
                    <Option v-for="item in sdNameList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="品牌">
                <Input v-model="editForm.brand" :maxlength="30" placeholder="品牌" clearable/>
            </FormItem>
            <FormItem label="规格型号">
                <Input v-model="editForm.model" :maxlength="30" placeholder="规格型号" clearable/>
            </FormItem>
            <FormItem label="出厂编码">
                <Input v-model="editForm.snCode" @on-keyup="btKeyUp($event)" @input="input" :maxlength="30"
                       placeholder="出厂编码" clearable/>
            </FormItem>
            <FormItem label="所属公司" prop="companyId">
                <Select v-model="editForm.companyId" filterable placeholder="请选择" clearable>
                    <Option v-for="item in companyList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="存放地点" prop="locationId">
                <treeselect v-model="editForm.locationId" :options="locationArray" placeholder="存放地点"
                            noOptionsText="暂无数据" noResultsText="没有匹配数据" clearable/>
            </FormItem>
            <FormItem label="资产照片">
                <Upload
                    ref="upload"
                    multiple
                    type="drag"
                    :max-size="20 * 1024"
                    :action="uploadFiles"
                    :show-upload-list="false"
                    :format="['jpg', 'png', 'JPG', 'JPEG']"
                    accept=".jpg,.png,.JPG,.JPEG"
                    :before-upload="beforeUpload"
                    :on-exceeded-size="handExceeded"
                    :on-success="handleSuccess"
                    :headers="header">
                    <div class="upload-btn-l">
                        <Icon type="md-cloud-upload"></Icon>
                        <p class="tips">点击上传或拖动文件到这里上传</p>
                    </div>
                </Upload>
                <div class="upload-image-list" v-for="(item, index) in imgUrl">
                    <template>
                        <img :src="item.url" style="width: 58px; height: 58px; line-height: 58px"/>
                        <div class="upload-image-list-cover">
                            <Icon type="ios-eye" @click.native="handleView(item.url)"></Icon>
                            <Icon type="ios-trash" @click.native="handleRemove(index)"></Icon>
                        </div>downloadFile
                    </template>
                </div>
            </FormItem>
            <Modal title="查看图片" v-model="visible" footer-hide>
                <img :src="imageUrl" v-if="visible" style="width: 100%" />
            </Modal>
            <div class="divider">来源信息</div>
            <FormItem label="资产管理员" prop="storeman">
                <Select v-model="editForm.storeman" filterable @on-change="getUser" placeholder="请选择" clearable>
                    <Option v-for="item in userArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="使用部门/车间">
                <treeselect v-model="editForm.departmentIds" :max-height="130" :options="deptTreeOptions"
                            placeholder="请选择部门" loadingText="加载中..." noOptionsText="暂无数据" noResultsText="没有匹配数据"
                            @select="handleDeptChange"/>
            </FormItem>
            <FormItem label="保管人" prop="user">
                <Select v-model="editForm.user" filterable placeholder="请选择" clearable>
                    <Option v-for="(item, index) in userNameArray" :value="item.id" :key="index">{{ item.name }}
                    </Option>
                </Select>
            </FormItem>
            <FormItem label="生产厂家" prop="manufacturer">
                <Input v-model="editForm.manufacturer" :maxlength="10" placeholder="生产厂家" clearable/>
            </FormItem>
            <FormItem label="生产日期" prop="productionDate">
                <Input v-model="editForm.productionDate" :maxlength="10" placeholder="生产日期" clearable/>
            </FormItem>
            <FormItem label="使用月限">
                <Input v-model="editForm.maximumMonthesToUse" @on-keyup="maximumMonthesToUse($event)" :maxlength="3"
                       placeholder="使用月限" clearable/>
            </FormItem>
            <FormItem label="增加方式名称" prop="sourceId">
                <Select v-model="editForm.sourceId" placeholder="请选择" clearable>
                    <Option v-for="item in sourceList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
            </FormItem>
            <FormItem label="原值" prop="amount">
                <Input v-model="editForm.amount" @on-keyup="amount($event)" :maxlength="11" placeholder="原值（元）"
                       clearable/>
            </FormItem>
            <FormItem label="计量单位">
                <Input v-model="editForm.unit" :maxlength="2" placeholder="计量单位" clearable/>
            </FormItem>
            <FormItem label="附件">
                <Upload ref="attachment"
                        multiple
                        type="drag"
                        :max-size="20 * 1024"
                        :action="uploadFiles"
                        :default-file-list="files"
                        :format="['jpg', 'png', 'JPG', 'JPEG', 'xlsx', 'xls', 'doc', 'docx', 'pdf']"
                        accept=".jpg,.png,.JPG,.JPEG,.xlsx,.xls,.doc,.docx,.pdf"
                        :before-upload="handleBeforeUpload"
                        :on-exceeded-size="handExceeded"
                        :on-remove="removeFile"
                        :on-success="handleFileSuccess"
                        :headers="header">
                    <div class="upload-btn-l">
                        <Icon type="md-cloud-upload"></Icon>
                        <p class="tips">点击上传或拖动文件到这里上传</p>
                    </div>
                </Upload>
            </FormItem>
            <div class="divider">其它信息</div>
            <FormItem label="开始使用日期">
                <DatePicker v-model="editForm.startTime" placeholder="开始使用日期" clearable/>
            </FormItem>
            <FormItem label="建卡日期">
                <DatePicker v-model="editForm.cardCreatedDate" placeholder="建卡日期" clearable/>
            </FormItem>
            <FormItem label="入库时间" prop="warehouseTime">
                <DatePicker v-model="editForm.warehouseTime" placeholder="入库时间" clearable/>
            </FormItem>
            <FormItem label="备注">
                <Input v-model="editForm.memo" type="textarea" :maxlength="200" placeholder="备注" clearable/>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import Treeselect from '@riophae/vue-treeselect'
import {reqGetDictionaryListByCode, uploadFileURL, downloadFileURL} from '../../../api/system-api'
import {
    reqEdit,
    reqGetAssetById,
    reqGetClassify,
    reqGetLocation,
    reqGetCompany,
    getUserListByRoleName,
    reqGetDeptTreeOptions,
    getUserByDeptId,
} from '../../../api/asset-api'

export default {
    components: {Treeselect},
    name: 'editManifest',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        id: '',
    },
    data() {
        return {
            deptTreeOptions: [],
            uploadImageName: [],
            uploadFileName: [],
            image: [],
            files: [],
            imgUrl: [],
            imageUrl: '',
            visible: false,
            uploadFiles: uploadFileURL,
            downloadFileURL: downloadFileURL,
            showForm: this.value,
            loading: false,
            classifyList: [],
            companyList: [],
            sdNameList: [],
            sourceList: [],
            locationArray: [],
            userArray: [],
            userNameArray: [],
            asset: '',
            header: {},
            editForm: {
                encoding: '',
                departmentIds: [],
                userName: '',
                name: '',
                classifyId: null,
                deptTreeOptions: '',
                model: '',
                brand: '',
                cardNo: '',
                snCode: '',
                warehouseTime: null,
                companyId: '',
                status: '',
                locationId: null,
                sourceId: '',
                amount: '',
                usageDueDate: null,
                maintenanceDueDate: null,
                startTime: null,
                memo: '',
                storeman: '',
                user: '',
                cardCreatedDate: null,
                maximumMonthesToUse: '',
                productionDate: '',
                manufacturer: '',
            },
            editFormRule: {
                encoding: [{message: '请输入资产编码', trigger: 'blur'}],
                name: [{required: true, message: '请输入资产名称', trigger: 'blur'}],
                classifyId: [{required: true, message: '请选择资产类别', trigger: 'change'}],
                companyId: [{required: true, message: '请选所属公司', trigger: 'change'}],
                locationId: [{required: true, message: '请选存放地点', trigger: 'change'}],
                warehouseTime: [{
                    required: true,
                    type: 'date',
                    message: '请选择入库时间',
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (!value) {
                            return callback(new Error('请选择入库时间'))
                        }
                        return callback()
                    }
                }],
                sourceId: [{required: true, type: 'integer', message: '请选择增加方式名称', trigger: 'change'}],
                storeman: [{required: true, message: '请选择资产管理员', trigger: 'change'}],
                status: [{required: true, type: 'integer', message: '请输入资产状态', trigger: 'change'}],
                user: [{message: '请选择使用人', trigger: 'change'}],
                deptTreeOptions: [{message: '请选择使用部门/车间', trigger: 'change'}],
                departmentIds: [{message: '请选择使用部门/车间', type: 'array', trigger: 'change'}],
                amount: [{
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value) {
                            if ((value + '').length > 11) {
                                return callback(new Error('最大金额99999999.99'))
                            } else if (value * 1 > 100000000) {
                                return callback(new Error('最大金额99999999.99'))
                            } else if (value * 1 < 0) {
                                return callback(new Error('最小金额不得小于0'))
                            }
                        }
                        return callback()
                    }
                }],
                productionDate: [{
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value) {
                            let regY = /^[1-9][0-9]{3}$/
                            let regYM = /^([1-9][0-9]{3})-(0[1-9]|1[0-2])$/
                            let regYMD =
                                /^(([1-9][0-9]{3})-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([1-9][0-9])(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29)$/
                            if (!regY.test(value) && !regYM.test(value) && !regYMD.test(value)) {
                                return callback(
                                    new Error('生产日期格式不正确！例如：yyyy或yyyy-mm或yyyy-mm-dd')
                                )
                            }
                        }
                        return callback()
                    }
                }]
            }
        }
    },
    methods: {
        getDeptTreeOptions() {
            this.deptTreeOptions = []
            reqGetDeptTreeOptions()
                .then((res) => {
                    this.deptTreeOptions = res.data.data
                })
                .catch(() => {
                    this.deptTreeOptions = []
                })
        },
        getUser() {
            getUserListByRoleName({roleName: '资产管理员'})
                .then((res) => {
                    this.userArray = res.data
                })
                .catch(() => {
                    this.userArray = null
                })
        },
        handleDeptChange(node, instanceId) {
            getUserByDeptId({dDeptId: node.id})
                .then((res) => {
                    this.userNameArray = res.data
                })
                .catch(() => {
                    this.userNameArray = null
                })
        },
        handleView(url) {
            this.imageUrl = url
            this.visible = true
        },
        handleRemove(index) {
            this.imgUrl.splice(index, 1)
            this.uploadImageName.splice(index, 1)
        },
        handleSubmit() {
            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    let paras = {
                        id: this.editForm.id,
                        deptTreeOptions: this.editForm.deptTreeOptions,
                        encoding: this.editForm.encoding,
                        name: this.editForm.name,
                        classifyId: this.editForm.classifyId,
                        brand: this.editForm.brand,
                        cardNo: this.editForm.cardNo,
                        model: this.editForm.model,
                        snCode: this.editForm.snCode,
                        status: this.editForm.status,
                        companyId: this.editForm.companyId,
                        locationId: this.editForm.locationId,
                        sourceId: this.editForm.sourceId,
                        amount: this.editForm.amount,
                        usageDueDate: this.editForm.usageDueDate,
                        unit: this.editForm.unit,
                        maintenanceDueDate: this.editForm.maintenanceDueDate,
                        startTime: this.editForm.startTime,
                        warehouseTime: this.editForm.warehouseTime,
                        memo: this.editForm.memo,
                        attachmentList: [...this.uploadImageName, ...this.uploadFileName],
                        storeman: this.editForm.storeman,
                        user: this.editForm.user,
                        departmentIds: this.editForm.departmentIds,
                        cardCreatedDate: this.editForm.cardCreatedDate,
                        maximumMonthesToUse: this.editForm.maximumMonthesToUse,
                        productionDate: this.editForm.productionDate,
                        manufacturer: this.editForm.manufacturer
                    }
                    reqEdit(paras).then((res) => {
                        this.loading = false
                        if (res.data.code === 1) {
                            this.closeModal(false)
                            this.$emit('getAsset')
                            this.$Message.success('编辑成功')
                            this.$refs.upload.clearFiles()
                            this.$refs.attachment.clearFiles()
                        } else if (res.data.code === 2) {
                            this.$Message.error('出厂编码已经存在')
                            this.$refs.upload.clearFiles()
                            this.$refs.attachment.clearFiles()
                        } else if (res.data.code === 3) {
                            this.$Message.error('资产编码已存在')
                            this.$refs.upload.clearFiles()
                            this.$refs.attachment.clearFiles()
                        } else if (res.data.code === 4) {
                            this.$Message.error('卡片编号已存在')
                            this.$refs.upload.clearFiles()
                            this.$refs.attachment.clearFiles()
                        } else {
                            this.$Message.error('编辑失败')
                            this.$refs.upload.clearFiles()
                            this.$refs.attachment.clearFiles()
                        }
                    }).catch(() => {
                        this.loading = false
                        this.closeModal(false)
                        this.$Message.error('编辑失败')
                        this.$refs.upload.clearFiles()
                        this.$refs.attachment.clearFiles()
                    })
                }
            })
        },
        getAssetById() {
            reqGetAssetById({id: this.id}).then((res) => {
                let result = res.data.data
                result.departmentIds = res.data.data.departmentId && res.data.data.departmentId.split(',')[0]
                this.editForm = result
                if (result.departmentIds) {
                    this.handleDeptChange({id: result.departmentIds})
                }
                // this.editForm.warehouseTime = this.$moment(res.data.data.warehouseTime).format("YYYY-MM-DD");
                res.data.data.fileList.forEach((item) => {
                    if (item.type === 'img') {
                        this.imgUrl.push({url: downloadFileURL + item.currentName + '/'})
                        this.uploadImageName.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        });
                        // this.image.push({name: item.orignalName})
                    }
                    if (item.type === 'file') {
                        this.files.push({
                            name: item.orignalName,
                            url: downloadFileURL + item.currentName + '/',
                            currentName: item.currentName,
                        })
                        this.uploadFileName.push({
                            fileName: item.orignalName,
                            newFileName: item.currentName,
                            type: item.type
                        })
                    }
                })
            }).catch(() => {
                this.editForm = []
            })
        },
        // 资产分类
        getClassifyName() {
            reqGetClassify().then((res) => {
                let options = []
                let child = []
                if (res.data.data !== null) {
                    for (let i = 0; i < res.data.data.length; i++) {
                        options.push({
                            id: res.data.data[i].id,
                            label: res.data.data[i].name,
                        })
                        if (!res.data.data[i].children) {
                            child.push({length: 0})
                        } else {
                            child.push(res.data.data[i].children)
                        }
                    }
                    let result = []
                    options.forEach((item, index) => {
                        let obj = {}
                        ;(obj.id = item.id), (obj.label = item.label)
                        if (child[index].length !== 0) {
                            obj.children = this.getTree(child[index])
                        }
                        result.push(obj)
                    })
                    this.classifyList = result
                }
            }).catch(() => {
                this.classifyList = []
            })
        },
        // 资产来源
        getSource() {
            reqGetDictionaryListByCode({code: 'source_of_asset'}).then((res) => {
                this.sourceList = res.data.data
            }).catch(() => {
                this.sourceList = []
            })
        },
        // 资产状态
        getSdName() {
            reqGetDictionaryListByCode({code: 'status_of_asset'}).then((res) => {
                this.sdNameList = res.data.data
            }).catch(() => {
                this.sdNameList = []
            })
        },
        // 所属公司
        getCompany() {
            reqGetCompany().then((res) => {
                this.companyList = res.data.data
            }).catch(() => {
                this.companyList = []
            })
        },
        // 所在位置
        getLocation() {
            reqGetLocation().then((res) => {
                let options = []
                let child = []
                if (res.data.data !== null) {
                    for (let i = 0; i < res.data.data.length; i++) {
                        options.push({
                            id: res.data.data[i].id,
                            label: res.data.data[i].name,
                        })
                        if (!res.data.data[i].children) {
                            child.push({length: 0})
                        } else {
                            child.push(res.data.data[i].children)
                        }
                    }
                    let result = []
                    options.forEach((item, index) => {
                        let obj = {}
                        obj.id = item.id
                        obj.label = item.label
                        if (child[index].length !== 0) {
                            obj.children = this.getTree(child[index])
                        }
                        result.push(obj)
                    })
                    this.locationArray = result
                }
            }).catch(() => {
                this.locationArray = []
            })
        },
        getTree(child = []) {
            let arr = []
            if (child.length !== 0) {
                child.forEach((element) => {
                    let obj = {}
                    if (element.hasOwnProperty('name')) {
                        obj.label = element.name
                        obj.id = element.id
                        if (element.hasOwnProperty('children')) {
                            obj.children = this.getTree(element.children)
                        }
                    }
                    arr.push(obj)
                })
            }
            return arr
        },
        handleBeforeUpload(file) {
            const check = this.files.length < 5
            if (!check) {
                this.$Notice.warning({
                    title: '附件最多上传5个',
                })
                return check
            }
            this.files.push(file)
        },
        removeFile(file, fileList) {
            this.files = [...fileList]
            let idx = this.uploadFileName.findIndex(
                (item) => item.newFileName === file.currentName || item.newFileName === file.response?.data
            )
            if (idx >= 0) {
                this.uploadFileName.splice(idx, 1)
            }
        },
        beforeUpload(file) {
            if (this.imgUrl.length === 5) {
                this.$Notice.warning({
                    title: '图片最多上传5张'
                })
                return false;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const _base64 = reader.result;
                this.imgUrl.push({url: _base64, name: file.name});
            }
        },
        //上传文件大小
        handExceeded() {
            this.$Message.error('文件内容不得超过20M')
        },
        //上传成功
        handleSuccess(res, file) {
            if (res.code === 1) {
                res.data.type = 'img';
                this.uploadImageName.push(res.data)
                this.$Message.success('上传成功')
            } else {
                this.$Message.error(res.data)
            }
        },
        //上传成功
        handleFileSuccess(res, file) {
            if (res.code === 1) {
                res.data.type = 'file';
                this.uploadFileName.push(res.data)
                this.$Message.success('上传成功')
            } else {
                this.$Message.error(res.data)
            }
        },
        //控制不能输入特殊字符
        btKeyUp($event) {
            if ('undefined' !== typeof $event) {
                this.editForm.snCode = this.editForm.snCode.replace(
                    /[`~!@#$%^&*()+=<>?:"{}|,.\;'[\]·~！@#￥%……&*（）——+={}|《》？：“”【】、；‘’，。、]/g,
                    ''
                )
            }
        },
        input() {
            let reg = /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\A9|\AE]\u3030|\uA9|\uAE|\u3030/gi
            this.editForm.snCode = this.editForm.snCode.replace(reg, '')
        },
        closeModal(val) {
            this.$emit('input', val)
        },
        amount($event) {
            if ('undefined' !== typeof $event) {
                this.editForm.amount = this.editForm.amount.replace(
                    /^\D*(\d*(?:\.\d{0,2})?).*$/g,
                    '$1'
                )
            }
        },
        maximumMonthesToUse($event) {
            if ('undefined' !== typeof $event) {
                this.editForm.maximumMonthesToUse =
                    this.editForm.maximumMonthesToUse.replace(/^(0+)|[^\d]+/g, '')
            }
        }
    },
    watch: {
        value(val) {
            this.showForm = val
        },
        showForm(val) {
            if (val) {
                this.editForm.storeman = ''
                this.editForm.userName = ''
                this.editForm.departmentIds = []
                this.imgUrl = []
                this.uploadFileName = []
                this.uploadImageName = []
                this.image = []
                this.files = []
                this.editForm.name = ''
                this.editForm.classifyId = null
                this.editForm.model = ''
                this.editForm.deptTreeOptions = ''
                this.editForm.brand = ''
                this.editForm.cardNo = ''
                this.editForm.snCode = ''
                this.editForm.status = ''
                this.editForm.warehouseTime = null
                this.editForm.companyId = ''
                this.editForm.locationId = ''
                this.editForm.sourceId = ''
                this.editForm.amount = ''
                this.editForm.usageDueDate = null
                this.editForm.maintenanceDueDate = null
                this.editForm.startTime = null
                this.editForm.memo = ''
                this.editForm.cardCreatedDate = null
                this.editForm.maximumMonthesToUse = ''
                this.editForm.productionDate = ''
                this.editForm.manufacturer = ''
                this.getDeptTreeOptions()
                this.getUser()
                this.getSource()
                this.getLocation()
                this.getSdName()
                this.getCompany()
                this.getClassifyName()
                this.getAssetById()
            } else {
                // 反之则关闭页面
                this.closeModal(val)
            }
        }
    },
    mounted() {
        let token = sessionStorage.getItem('Authorization')
        this.header = { Authorization: token }
    }
}
</script>

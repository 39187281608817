/**
 * Created by TapFun on 2022/03/04.
 */
import request from '@/common/request';
import {SERVER_HOST} from '../common/portConfig';

// Example
export const reqExport = params => {
    return request.post(`asset/export`, params, {responseType: 'blob'})
};

// 资产位置导入
export const reqVerifyAndSaveLocation = `${SERVER_HOST}/location/verifyAndSave`;

export const reqImport = params => {
    return request.post(`asset/importExcel`,
        params,
        {"Content-Type": "multipart/form-data", Authorization: sessionStorage.getItem("Authorization")}
    )
};
// Excel模板文件下载
export const reqDownloadExcelTemplate = params => {
    return request.post(`/downloadExcelTemplate`, params, {responseType: 'blob'})
};

<template>
  <div class="main-content">
    <div class="breadcrumb">
      <Breadcrumb>
        <BreadcrumbItem>资产管理</BreadcrumbItem>
        <BreadcrumbItem>资产清单</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="filter">
      <Form label-colon inline>
        <FormItem>
          <treeselect
            v-model="filter.departmentIds"
            :max-height="130"
            :multiple="true"
            :options="deptTreeOptions"
            placeholder="请选择部门"
            loadingText="加载中..."
            noOptionsText="暂无数据"
            noResultsText="没有匹配数据"
          />
        </FormItem>
          <FormItem>
              <Input
                  v-model="filter.cardNo"
                  placeholder="请输入卡片编号"
                  clearable
              />
          </FormItem>
        <FormItem>
          <Input
            v-model="filter.keywords"
            placeholder="请输入资产名称，编码，保管人，存放地点"
            clearable
            class="large-input"
          />
        </FormItem>
        <FormItem>
          <Button type="primary" @click="doSearching">搜索</Button>
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            icon="md-add"
            @click="add"
            v-if="permissionButton.add"
            >新增</Button
          >
        </FormItem>
        <FormItem>
          <Button
            icon="md-cloud-download"
            @click="exportExcel"
            v-if="permissionButton.export"
            >导出搜索结果</Button
          >
        </FormItem>
        <FormItem>
          <Button
            icon="md-cloud-upload"
            v-if="permissionButton.import"
            @click="upload = true"
            >批量导入</Button
          >
        </FormItem>
        <FormItem>
          <Modal v-model="upload" title="导入" @on-cancel="cancel">
            <div class="import-data">
              <Button
                class="download-btn"
                type="primary"
                icon="md-cloud-download"
                @click="downloadTemplate"
                >下载Excel模板
              </Button>
              <Upload
                class="upload-btn"
                ref="upload"
                :action="reqVerifyAndSaveAssets"
                :format="['xlsx', 'xls']"
                accept=".xlsx,.xls"
                :show-upload-list="false"
                :before-upload="handleBeforeUpload"
                :on-success="handleSuccess"
                :on-error="handleImportError"
                :headers="header"
              >
                <Button
                  type="primary"
                  v-if="permissionButton.import"
                  icon="md-cloud-upload"
                  :loading="loading"
                  >导入Excel文件
                </Button>
              </Upload>
              <div class="import-tips">
                注意：导入数据前请先下载Excel模版，编辑后请点击导入Excel数据按钮上传
              </div>
              <div class="import-errors" v-if="importErrorArray.length > 0">
                <Table
                  stripe
                  :columns="importErrorColumns"
                  :data="importErrorArray"
                ></Table>
              </div>
            </div>
            <div slot="footer">
              <Button type="primary" @click="cancel">确定</Button>
            </div>
          </Modal>
        </FormItem>
        <FormItem>
          <Button
            icon="md-print"
            v-if="permissionButton.print"
            :disabled="selectedAssets.size === 0"
            @click="handlePrintLabel"
            :loading="printLoading"
            >打印标签
          </Button>
        </FormItem>
      </Form>
    </div>
    <div class="table">
      <div class="table-list">
        <Table
          stripe
          :columns="columns"
          :data="assetList"
          :loading="loading"
          @on-select="handleSelect"
          @on-select-cancel="handleCancel"
          @on-select-all="handleSelectAll"
          @on-select-all-cancel="handleAllCancel"
        >
          <template slot-scope="{ row, index }" slot="action">
            <a @click="edit(row, index)" v-if="permissionButton.edit">编辑</a>
            <Divider type="vertical" />
            <a @click="view(row, index)" v-if="permissionButton.view">查看</a>
            <Divider type="vertical" />
            <a class="errors" @click="del(row, index)" v-if="permissionButton.del">删除</a>
          </template>
        </Table>
        <showAdd v-model="addShow" v-on:getAsset="getAsset"></showAdd>
        <showEdit
          v-model="editShow"
          :id="id"
          v-on:getAsset="getAsset"
        ></showEdit>
        <showLook v-model="lookShow" :id="id"></showLook>
      </div>
      <div class="table-footer">
        <Row>
          <Col class="total" span="6"
            >共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col
          >
          <Col class="pagenation" span="18">
            <Page
              :page-size="pageSize"
              :total="total"
              :current="pageNum"
              :page-size-opts="[15, 20, 50, 100]"
              @on-change="changePage"
              @on-page-size-change="changPageSize"
              show-sizer
              show-elevator
            />
          </Col>
        </Row>
      </div>
    </div>
  </div>
</template>

<script>
  import permission from '@/common/permission'
  import {
    reqAssetPage,
    reqDel,
    reqAvailTemplate,
    reqAddPrintHistory,
    reqGetDeptTreeOptions,
    reqVerifyAndSaveAssets,
  } from '../../../api/asset-api'
  import {reqExport, reqImport, reqDownloadExcelTemplate} from '../../../api/exportToExcel-api'
  import showAdd from './add.vue'
  import showEdit from './edit.vue'
  import showLook from './view.vue'
  import Treeselect from '@riophae/vue-treeselect'
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import { getChildrenById } from '../../../assets/js/treeFun'

  export default {
    name: 'manifestList',
    components: {
      showAdd,
      showEdit,
      showLook,
      Treeselect,
    },
    data() {
      return {
        file: '',
        reqVerifyAndSaveAssets: reqVerifyAndSaveAssets,
        filter: {
          keywords: '',
          departmentIds: [],
            cardNo: ''
        },
        header: {},
        lookData: '',
        id: '',
        upload: false,
        loading: false,
        assetList: [],
        deptTreeOptions: [],
        addShow: false,
        editShow: false,
        lookShow: false,
        selectedAssets: new Map(),
        printLoading: false,
        importErrorArray: [],
        permissionButton: {
          add: false,
          edit: false,
          del: false,
          view: false,
          import: false,
          export: false,
          print: false,
        },
        total: 0,
        pages: 1,
        pageSize: 20,
        pageNum: 1,
        importErrorColumns: [
          {
            type: 'index',
            width: 60,
            align: 'center',
          },
          {
            title: '错误信息',
            key: 'error',
          },
        ],
        columns: [
          {
            type: 'selection',
            width: 60,
            align: 'center',
          },
          {
            title: '卡片编号',
            key: 'cardNo',
            tooltip: 'ture',
            width: 120,
          },
          {
            title: '资产编码',
            key: 'encoding',
            tooltip: 'ture',
            width: 180,
          },
          {
            title: '资产名称',
            key: 'name',
            tooltip: 'ture',
            minWidth: 200,
          },
          {
            title: '资产分类',
            key: 'classifyName',
            tooltip: 'ture',
            width: 150,
          },
          {
            title: '品牌',
            key: 'brand',
            tooltip: 'ture',
            minWidth: 220,
          },
          {
            title: '型号',
            key: 'model',
            tooltip: 'ture',
            minWidth: 250,
          },
          {
            title: 'SN码',
            key: 'snCode',
            tooltip: 'ture',
            width: 180,
          },
          {
            title: '所在位置',
            key: 'locationName',
            tooltip: 'ture',
            minWidth: 200,
          },
          {
            title: '保管人',
            key: 'user',
            tooltip: 'ture',
            width: 100,
          },
          {
            title: '使用部门/车间',
            key: 'departmentName',
            tooltip: 'ture',
            width: 250,
          },
          {
            title: '资产状态',
            key: 'sdName',
            tooltip: 'ture',
            width: 100,
            render: (h, params) => {
              return h('span', [
                h(
                  'span',
                  {
                    style: {
                      color:
                        params.row.sdName === '闲置' ? '#19be6b' : '#ff9900',
                    },
                  },
                  params.row.sdName === '闲置' ? '闲置' : params.row.sdName
                ),
              ])
            },
          },
          {
            title: '盘点状态',
            key: 'isInventory',
            tooltip: 'ture',
            width: 100,
          },
          {
            title: '开始使用日期',
            key: 'startTime',
            tooltip: 'ture',
            width: 130,
          },
          {
            title: '入库时间',
            tooltip: 'ture',
            width: 130,
            render: (h, params) => {
              return h(
                'span',
                this.$moment(params.row.warehouseTime).format('YYYY-MM-DD')
              )
            },
          },
          {
            title: '操作',
            slot: 'action',
            width: 150,
            align: 'center',
            fixed: 'right',
          },
        ],
      }
    },
    methods: {
      getAsset() {
        this.loading = true
        this.assetList = []
        let deptIds = []
        this.filter.departmentIds.map((item) => {
          deptIds = [
            ...deptIds,
            ...getChildrenById(this.deptTreeOptions, item, []),
          ]
        })
        let paras = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          keywords: this.filter.keywords,
          deptIds: deptIds,
            cardNo: this.filter.cardNo
        }
        reqAssetPage(paras)
          .then((res) => {
            this.loading = false
            res.data.data.records.forEach((item) => {
              if (item.isInventory === 1) {
                item.isInventory = '盘点中'
              } else {
                item.isInventory = ''
              }

              if (this.selectedAssets.has(item.id)) {
                item['_checked'] = true
              }
            })
            this.assetList = res.data.data.records
            this.pageNum = res.data.data.current
            this.pages = res.data.data.pages
            this.total = res.data.data.total
          })
          .catch(() => {
            this.assetList = []
            this.pages = ''
            this.total = ''
          })
      },
      handleSelect(selection, row) {
        this.selectedAssets = new Map(this.selectedAssets.set(row.id, row))
      },
      handleCancel(selection, row) {
        this.selectedAssets.delete(row.id)
        this.selectedAssets = new Map(this.selectedAssets)
      },
      handleSelectAll(selection) {
        selection.forEach((item) => {
          this.selectedAssets.set(item.id, item)
        })
        this.selectedAssets = new Map(this.selectedAssets)
      },
      handleAllCancel(selection) {
        this.assetList.forEach((item) => {
          this.selectedAssets.delete(item.id)
        })
        this.selectedAssets = new Map(this.selectedAssets)
      },
      handlePrintLabel() {
        let that = this
        if (!that.selectedAssets || that.selectedAssets.size === 0) {
          that.$Message.error('请选择资产')
          return
        }

        that.printLoading = true
        reqAvailTemplate()
          .then((res) => {
            if (!res.data.data || !res.data.data.id) {
              that.$Message.error('未设置标签模板')
              that.printLoading = false
            } else {
              let label = []
              that.selectedAssets.forEach((item) => {
                label.push({
                  tempId: res.data.data.id,
                  assetId: item.id,
                  assetName: item.name,
                  assetCompany: item.companyName,
                  assetModel: item.model,
                  assetClassify: item.classifyName,
                  assetEncoding: item.encoding,
                  assetCardNo: item.cardNo,
                  assetSnCode: item.snCode,
                  status: 0,
                })
              })

              reqAddPrintHistory(label)
                .then((res2) => {
                  if (res2.data.code === 1) {
                    that.$Message.success('打印任务添加完成')
                  } else {
                    that.$Message.error('打印任务添加失败')
                  }
                  that.printLoading = false
                })
                .catch(() => {
                  that.$Message.error('打印任务添加失败')
                  that.printLoading = false
                })
            }
          })
          .catch(() => {
            that.$Message.error('打印失败')
            that.printLoading = false
          })
      },
      add(row, index) {
        this.addShow = true
      },
      edit(row, index) {
        this.id = row.id
        this.editShow = true
      },
      view(row, index) {
        this.id = row.id
        this.lookShow = true
      },
      del(row, index) {
        this.$Modal.confirm({
          title: '删除资产',
          content: '是否删除资产信息',
          width: '240',
          onOk: () => {
            reqDel({ id: row.id }).then((res) => {
              if (res.data.code === 1) {
                this.getAsset()
                this.$Message.success('删除成功')
              } else {
                this.$Message.success('删除失败')
              }
            })
          },
        })
      },
      exportExcel() {
        this.$Modal.confirm({
          title: '导出数据',
          content: '确认导出数据？',
          width: '240',
          onOk: () => {
            let deptIds = []
            this.filter.departmentIds.map((item) => {
              deptIds = [
                ...deptIds,
                ...getChildrenById(this.deptTreeOptions, item, []),
              ]
            })
            let params = {
              keywords: this.filter.keywords,
              deptIds: deptIds,
            }
            reqExport(params).then((res) => {
              if (res.data.size > 0) {
                var blob = new Blob([res.data], {
                  type: 'application/vnd.openxmlformats-  officedocument.spreadsheetml.sheet;charset=utf-8',
                }) //type这里表示xlsx类型

                var downloadElement = document.createElement('a')
                var href = window.URL.createObjectURL(blob) //创建下载的链接
                downloadElement.href = href
                downloadElement.download = '资产清单.xlsx' //下载后文件名
                document.body.appendChild(downloadElement)
                downloadElement.click() //点击下载
                document.body.removeChild(downloadElement) //下载完成移除元素
                window.URL.revokeObjectURL(href) //释放掉blob对象
              } else {
                this.$Message.error('导出失败')
              }
            })
          },
        })
      },
      handleBeforeUpload(file) {
        this.loading = true
        this.importErrorArray = []
      },
      handleSuccess(response) {
        this.loading = false
        if (response.status === 1) {
          this.$Message.success('导入成功')
          this.getAsset()
        } else {
          this.importErrorArray = []
          JSON.parse(response.errors).forEach((item) => {
            this.importErrorArray.push({ error: item })
          })
          this.$Message.error('导入失败')
        }
      },
      handleImportError(error, file, fileList) {
        this.loading = false
        this.$Message.error('导入失败')
      },
      downloadTemplate() {
        let fileName = 'Asset.xlsx'
        reqDownloadExcelTemplate({ fileName: fileName })
          .then((res) => {
            const link = document.createElement('a')
            const blob = new Blob([res.data], {
              type: 'application/vnd.ms-excel',
            })
            link.style.display = 'none'
            link.href = URL.createObjectURL(blob)
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
          .catch(() => {
            this.$Message.error('文件不存在')
          })
      },
      onOk() {
        this.loading = true
        let file = this.file
        if ('undefined' != typeof file && file !== '') {
          var formData = new FormData()
          formData.append('file', file)
          reqImport(formData)
            .then((res) => {
              this.loading = false
              if (res.data.code === 1) {
                this.$Message.success('导入成功')
                this.upload = false
                this.$refs.upload.clearFiles()
                this.getAsset()
              } else {
                this.upload = false
                this.$refs.upload.clearFiles()
                this.$Message.error('导入失败')
              }
            })
            .catch(() => {
              this.loading = false
              this.upload = false
              this.$refs.upload.clearFiles()
              this.$Message.error('导入失败')
            })
        } else {
          this.upload = false
          this.loading = false
        }
      },
      cancel() {
        this.upload = false
        this.importErrorArray = []
        this.$refs.upload.clearFiles()
      },
      //分页点击，切换页面
      changePage(val) {
        this.pageNum = val
        this.getAsset()
      },
      //每页显示的数据条数
      changPageSize(numOfRows) {
        //实时获取当前需要显示的条数
        this.pageSize = numOfRows
        this.getAsset()
      },
      doSearching() {
        this.pageNum = 1
        this.selectedAssets = new Map()
        this.getAsset()
      },
      getDeptTreeOptions() {
        this.deptTreeOptions = []
        reqGetDeptTreeOptions()
          .then((res) => {
            this.deptTreeOptions = res.data.data
          })
          .catch(() => {
            this.deptTreeOptions = []
          })
      },
    },
    mounted() {
      this.getDeptTreeOptions()
      this.getAsset()
      let token = sessionStorage.getItem('Authorization')
      this.header = { Authorization: token }
      let permissionId = this.$route.query.permissionId
      permission.getPermissionButton(permissionId, this.permissionButton)
    },
  }
</script>
